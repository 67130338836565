<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-5">
        <div class="card card-custom shadow">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12 my-5" v-if="getIsCod">
                <div class="mx-4">
                  <div class="my-5">
                    <span class="icon status-icon submitted-icon"></span>
                  </div>

                  <h4 class="text-center fw-bolder text-navy-blue">
                    Application Submitted
                  </h4>
                  <div class="text-center mt-3">
                    <strong>Dear, {{ applicationData.firstName }}</strong>
                  </div>
                  <div class="text-center mt-3">
                    Thank you! Your application number is
                    <span class="text-orange fw-bold">{{
                      GET_PREDATA.referenceNumber
                    }}</span>
                  </div>
                  <div class="text-center mt-3">
                    You selected data pack:
                    <strong
                      >{{ getDataPack.skuName }} - Php
                      {{ getDataPack.price }}</strong
                    >
                  </div>
                  <div class="text-center mt-3">
                    Prefered Date and Time of Installation is:
                    <strong>{{ GET_CODRESPONSE }}</strong>
                  </div>

                  <div class="text-center mt-3" v-if="getAffiliateCode">
                    Affilliate Code:
                    <span class="text-orange fw-bold">{{
                      getAffiliateCode
                    }}</span>
                  </div>

                  <div class="text-center mt-3">
                    Please expect a call from our customer support team.
                  </div>

                  <div class="text-center mt-5">
                    Thank you for choosing Surf2Sawa!
                  </div>
                  <div class="text-center mt-3">
                    <strong>Surf2Sawa Team</strong>
                  </div>
                  <!-- 
                  <div class="text-center mt-3">
                    Application Reference Number
                    <div class="reference-number">{{ refNumber }}</div>
                  </div> -->

                  <div class="my-5 text-center">
                    <button
                      class="btn px-5 btn-s2s-blue"
                      @click.prevent="goBack()"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 my-5" v-else>
                <div class="mx-4">
                  <div class="my-5">
                    <span class="icon status-icon submitted-icon"></span>
                  </div>

                  <h4 class="text-center fw-bolder text-navy-blue">
                    Application Submitted
                  </h4>
                  <div class="text-center mt-3">
                    <strong>Dear, {{ applicationData.firstName }}</strong>
                  </div>
                  <div class="text-center mt-3">
                    Your payment of
                    <strong>Php {{ getDataPack.price }}</strong> was
                    successfully processed! Your application number is:
                    <span class="text-orange fw-bold">{{ getRefNumber }}</span>
                  </div>
                  <div class="text-center mt-3">
                    Please save your application number for any inquiries you
                    may have about your application.
                  </div>
                  <div class="text-center mt-3">
                    Kindly give us 3 to 5 days to install your prepaid internet.
                    A representative will give you updates on your application
                    so make sure to keep your lines open.
                  </div>

                  <div class="text-center mt-3" v-if="getAffiliateCode">
                    Affilliate Code:
                    <span class="text-orange fw-bold">{{
                      getAffiliateCode
                    }}</span>
                  </div>

                  <div class="text-center mt-5">
                    Thank you for choosing Surf2Sawa!
                  </div>
                  <div class="text-center mt-3">
                    <strong>Surf2Sawa Team</strong>
                  </div>
                  <!-- 
                  <div class="text-center mt-3">
                    Application Reference Number
                    <div class="reference-number">{{ refNumber }}</div>
                  </div> -->

                  <div class="my-5 text-center">
                    <button
                      class="btn px-5 btn-s2s-blue"
                      @click.prevent="goBack()"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  components: {},

  data() {
    return {
      modalMaps: {
        isShow: false,
      },

      refNumber: "",
      submitted: false,
    };
  },

  methods: {
    goBack() {
      this.$store.dispatch("ADD_PREDATA", null);
      this.$store.dispatch("ADD_AFFILIATECODE", "");
      this.$store.dispatch("ADD_IS_COD", false);
      this.$store.dispatch("ADD_COD_RESPONSE", {});
      this.$store.dispatch("ADD_APPLICATION_FORM", false).then(() => {
        this.$router.push({ path: "/" });
      });
    },

    reFormatAccountNumber(num) {
      return num.replace(/\s+/g, "").replace("+", "");
    },

    installaDate() {},
  },

  created() {
    // const refNumber = this.$store.getters.GET_REFNUMBER;
    // if (refNumber) {
    //   this.refNumber = refNumber;
    // } else {
    //   this.$router.push({ path: "/" });
    // }
    //
    const appData = this.$store.getters.GET_APPLICATIONFORMDATA;
    const isEmpty = Object.keys(appData).length === 0;
    if (isEmpty) {
      this.$router.push({ path: "/" });
      return;
    }
  },

  computed: {
    applicationData() {
      return this.$store.getters.GET_APPLICATIONFORMDATA;
    },

    getDataPack() {
      return this.$store.getters.GET_DATAPACK;
    },

    getRefNumber() {
      return this.$store.getters.GET_REFNUMBER;
    },

    getAffiliateCode() {
      return this.$store.getters.GET_AFFILIATECODE;
    },

    getIsCod() {
      return this.$store.getters.GET_ISCOD;
    },

    GET_PREDATA() {
      return this.$store.getters.GET_PREDATA;
    },

    GET_CODRESPONSE() {
      const res = this.$store.getters.GET_CODRESPONSE;
      return moment(res.installationDate).format("dddd MMM. DD, YYYY hh:mm A");
    },
  },

  validations: {
    accountNumber: {
      required,
    },
  },
};
</script>

<style scoped>
/* @import "../assets/css/reset.css";
@import "../assets/css/sweetalert2.min.css";
@import "../assets/css/superwheel.min.css"; */
</style>
